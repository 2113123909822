.logos {
    aspect-ratio: 3/2;
    object-fit: contain;
    width: 55%;
}

@media only screen and (max-width: 500px) {
    .logos {
        width: 65%;
    }
}